import React, { useState, useEffect, useRef } from 'react';
import { DatePicker, TimePrecision } from '@blueprintjs/datetime';
import { Icon } from '@blueprintjs/core';
import { Button } from 'componentsV2';
import './DateCellEdit.scss';
import { formatDate } from 'utilities/dates';

export const DateCellEdit = ({ editorProps, date, row, onSubmit }) => {
	const [selectedDate, setSelectedDate] = useState(null);
	const [isExpanded, setIsExpanded] = useState(false);
	const editorRef = useRef(null);

	const minDateValue = new Date(row.year, 6, 1); // Setting July 1st as MIN date according to the drill year.
	const maxDateValue = new Date(row.year + 1, 5, 30); // June 30th as MAX date according to the drill year.

	const defaultDateValue = date ? new Date(date) : minDateValue;

	const handleOnChange = value => {
		setSelectedDate(value);
	};

	const submitDate = () => {
		onSubmit(row, selectedDate);
		editorProps.onUpdate(selectedDate);
	};

	useEffect(() => {
		setIsExpanded(true);
		if (date) {
			setSelectedDate(date);
		}
	}, []);

	useEffect(() => {
		// Function to check if clicked outside of editor
		function handleClickOutside(event) {
			if (editorRef.current && !editorRef.current.contains(event.target)) {
				setIsExpanded(false);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [editorRef, selectedDate]);

	return (
		<div className="date-cell-edit-container" ref={editorRef}>
			{/* Fake date Input div */}
			<div
				onClick={() => setIsExpanded(!isExpanded)}
				className="drills-table-schedule-date-cell"
			>
				<Icon icon="calendar" />
				<span>{formatDate(selectedDate ? selectedDate : new Date().toLocaleString())}</span>
			</div>
			<div className={`date-cell-edit-datepicker ${isExpanded ? 'expanded' : ''}`}>
				<DatePicker
					maxDate={maxDateValue}
					minDate={minDateValue}
					defaultValue={defaultDateValue}
					onChange={handleOnChange}
					timePrecision={TimePrecision.MINUTE}
					timePickerProps={{ useAmPm: true, showArrowButtons: true }}
				/>
				<Button
					wrapperClass="date-cell-edit-button"
					type="primaryDefault"
					text="Save"
					onClick={submitDate}
				/>
			</div>
		</div>
	);
};
